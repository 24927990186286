<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="countryMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
              <feather-icon icon="SkipBackIcon" /> {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group :label="$t('Country Master List.Country Name')">
              <validation-provider
                #default="{ errors }"
                name="Country Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.country_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Country Master List.Country Name')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Country Master List.Country Code')">
              <validation-provider
                #default="{ errors }"
                name="Country Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.country_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Country Master List.Country Code')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('Country Master List.Country ISD Code')">
              <validation-provider
                #default="{ errors }"
                name="Country ISD Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.country_isd_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Country Master List.Country ISD Code')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="text-center">
            <b-button variant="primary" type="submit" @click.prevent="saveCountry">
              {{ $t("Common.Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import MasterServices from "@/apiServices/MasterServices";

export default {
  name: "form",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      country_id: null,
      form: {
        country_name: "",
        country_code: "",
        country_isd_code: "",
        country_is_active: true,
      },
      required,
    };
  },
  methods: {
    saveCountry() {
      this.$refs.countryMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.country_id = this.country_id;
            // console.log("this.form=>",this.form);return false;
            const countryMaster = await MasterServices.saveCountry(this.form);

            if (countryMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: countryMaster.data.message || "Country Added Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.go(-1);
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: countryMaster.data.message || "Cannot Add Country",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveCountry ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveCountry",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleCountry(country_id) {
      try {
        const response = await MasterServices.getSingleCountry(country_id);
        let singleCountry = response.data.data;
        if (response.data.status) {
          this.form = {
            country_name: singleCountry.country_name,
            country_code: singleCountry.country_code,
            country_isd_code: singleCountry.country_isd_code,
            country_is_active: singleCountry.country_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    if (this.$route.params.country_id && !isNaN(this.$route.params.country_id)) {
      this.country_id = this.$route.params.country_id;
      this.getSingleCountry(this.country_id);
    }
  },
};
</script>

<style></style>
